<template>
  <div>
    <div v-show="dropdownActive" class="click-outside-overlay" @click="toggleDropdown()" />
    <div class="dropdownS">
      <div
        class="dropdownS-input"
        @click="toggleDropdown()"
        :class="{ active: dropdownActive }"
      >
        <div>
          <span
            v-for="(option, index) in value"
            :key="option.label + index + '_selected'"
          >{{ option.label }}<i slot="close" class="pointer icon-close" @click.stop="removeOptionFromSelected(option)" /></span>
        </div>
        <label>{{ placeholder }}</label>
      </div>
      <div v-show="dropdownActive" class="dropdownS-selector">
        <div class="dropdownS-scrollBlock">
          <span
            v-for="(option, index) in options"
            :key="option.label + index + '_row'"
            class="mobile-text"
            :class="{
              active:
                isOptionSelected(option),
              background:
                isOptionSelected(option),
            }"
            @click="clickOption(option)"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'MultiSelect',
  components: {
    ValidationMessages
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    validations: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      required: true,
      default: () => ''
    },
    value: {
      type: [Array, null],
      default: () => []
    },
    type: {
      type: String,
      default: 'multi'
    }
  },
  watch: {
    value (state) {
      if (!state || state.length === 0) this.selectedOptions = []
    }
  },
  data () {
    return {
      dropdownActive: false,
      selectedOptions: []
    }
  },
  methods: {
    toggleDropdown () {
      if (this.dropdownActive) this.$emit('blur')
      this.dropdownActive = !this.dropdownActive;
    },
    clickOption (clickedOption) {
      if (this.isOptionSelected(clickedOption)) return this.removeOptionFromSelected(clickedOption)
      if (this.type === 'multi') {
        this.selectedOptions.push(clickedOption)
      } else if (this.type === 'single') {
        this.selectedOptions = [clickedOption]
        this.toggleDropdown()
      }
      this.$emit('input', this.selectedOptions)
    },
    isOptionSelected (option) {
      return this.value && this.value.find(selected => selected.label === option.label)
    },
    removeOptionFromSelected (option) {
      this.selectedOptions = this.selectedOptions.filter(selectedOption => selectedOption.label !== option.label)
      this.$emit('input', this.selectedOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
.click-outside-overlay {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dropdownS {
  position: relative;
  cursor: pointer;
  width: 100%;
  &-input {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 45%;
      right: 7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: #707070 transparent transparent transparent;
      pointer-events: none;
      @media (max-width: 767px) {
        top: 50%;
      }
    }
    & > div {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      overflow: hidden;
    }
    & > div > span {
      position: relative;
      white-space: nowrap;
      background-color: #F5F5F5;
      font-weight: 500;
      padding: 3px 22px 3px 10px;
      border-radius: 5px;
      margin-right: 5px;
      .icon-close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        max-height: 20px
      }
    }
    &.active {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: none !important;
    }
    & > label {
      position: absolute;
      background-color: #fff;
      color:#707070;
      top: -7px;
      left: 12px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 1em;
      font-weight: 400;
      pointer-events:none;
      user-select: none;
    }
    &:hover {
      outline: none;
      border-color: black;
    }
    min-height: 24px;
    border: 1px solid #D6D8DE;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 6px 10px;
    font-weight: 400;
    font-size: 0.8em;
  }
  &-selector {
    span {
      &:last-of-type {
        margin-bottom: 10px;
      }
      &:before {
        content: "";
        position: relative;
        top: 3px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 15px;
        height: 15px;
        top: 0;
        background: #fff;
        border: 1px solid #D6D8DE;
      }
      &:hover:before {
        content: "";
        background: black;
      }
      &.active:before {
        content: "";
        background: black;
      }
      &:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 8px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
      }
      position: relative;
      margin: 5px 0;
      display: inline-block;
      width: 100%;
      color: #002138;
      font-weight: 400;
      font-size: 13px;
    }
    overflow: hidden;
    position: absolute;
    z-index: 5;
    top: calc(100% - 1px);
    background-color: white;
    border: 1px solid #D6D8DE;
    border-top: 0;
    padding-left: 10px;
    left: 0;
    right: 0;
  }
  &-footer {
    display: flex;
    margin-left: -25px;
    margin-right: -25px;
  }
  &-scrollBlock {
    max-height: 150px;
    overflow-y: scroll;
    margin-bottom: 10px;
  }
  &-button {
    width: 50%;
    border: 0;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #002138;
  }
  &-button.secondary {
    background-color: #D9D9D9;
  }
  &-divider {
    margin: 10px 0;
    border-bottom: 1px solid #D9D9D9;
  }
  .icon-close {
    font-weight: 600
  }
}
</style>
